import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropType from "prop-types"

import styles from "./NotFound.module.scss"
import Link from "../Link/Link"
import { Container } from "react-bootstrap"
import MarkdownTitle from "../MarkdownTitle"

import bg404 from "../../images/error-404.svg"

const NotFound = (props) => {
  const {
    type,
    image,
    title,
    message,
    ctaTitle,
    ctaURL,
    locale,
    defaultLocale,
    linkHome
  } = props

  const renderButton = (link) => {
    const buttonProps = {
      link: { ...link },
      locale,
      defaultLocale
    }
    console.log("🚀 ~ renderButton ~ buttonProps:", buttonProps)

    return (
      <div className={styles.link_button}>
        <Link {...buttonProps} />
      </div>
    )
  }

  return (
    <>
      {type !== "new" ? (
        <div className={styles.notFound}>
          {image && <GatsbyImage image={image.gatsbyImageData} alt="" />}
          <h1 className={[styles.title, "h2"].join(" ")}>
            <MarkdownTitle text={title} />
          </h1>
          <p>{message}</p>
          <a href={ctaURL}>{ctaTitle}</a>
        </div>
      ) : (
        <Container
          fluid
          className={styles.notFoundNew}
          style={{
            backgroundImage: bg404
          }}
        >
          <div className={styles.notFoundWrapper}>
            <div className={styles.notFoundBox}>
              <h1 className={[styles.title, "h2"].join(" ")}>ERROR</h1>
              <h1 className={[styles.titleB, "h2"].join(" ")}>404</h1>
              <p>This page does not exist</p>
              <div className={styles.linkWrapper}>
                {renderButton(linkHome)}
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

NotFound.propType = {
  image: PropType.object,
  image404: PropType.object,
  title: PropType.string,
  titleA: PropType.string.isRequired,
  titleB: PropType.string.isRequired,
  message: PropType.string,
  ctaTitle: PropType.string,
  ctaURL: PropType.string,
  type: PropType.string,
  locale: PropType.string,
  defaultLocale: PropType.string,
  linkHome: PropType.object
}

export default NotFound