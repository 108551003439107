import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  LEFT_PAGE,
  RIGHT_PAGE,
  pageNumbers
} from "../../utilities/paginationHelpers"
import arrowNav from "../../images/icons/arrow-nav.svg"
import styles from "./Pager.module.scss"

const Pager = ({ className, totalPages, currentPage, linkGenerator }) => {
  const [windowWidth, setWindowWidth] = useState(undefined)

  const windowResizeHandler = event => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      windowResizeHandler()

      window.addEventListener("resize", windowResizeHandler)

      return () => {
        window.removeEventListener("resize", windowResizeHandler)
      }
    }
  }, [])

  if (totalPages <= 1) return null

  let sidePages = 1

  if (windowWidth >= 768) {
    sidePages = 4
  } else if (windowWidth >= 576) {
    sidePages = 3
  } else if (windowWidth >= 375) {
    sidePages = 2
  }

  const pages = pageNumbers(totalPages, currentPage, sidePages)

  return (
    <div className={className} style={{ padding: "1rem 0"}}>
      <ul className="pagination justify-content-center">
        {pages.map((page, index) => {
          let pageNumber = page
          if (pageNumber === LEFT_PAGE) {
            pageNumber = currentPage - 1
          }
          if (pageNumber === RIGHT_PAGE) {
            pageNumber = currentPage + 1
          }

          const path = linkGenerator(pageNumber)

          if (page === LEFT_PAGE) {
            return (
              <li key={index} className={styles.pageItem}>
                <a
                  className={styles.pageLink}
                  href={path}
                  aria-label="Previous"
                >
                  <img
                    src={arrowNav}
                    alt="previous"
                    style={{ transform: "rotate(180deg)", height: "1rem" }}
                  />
                </a>
              </li>
            )
          }

          if (page === RIGHT_PAGE) {
            return (
              <li key={index} className={styles.pageItem}>
                <a className={styles.pageLink} href={path} aria-label="Next">
                  <img src={arrowNav} alt="next" style={{ height: "1rem" }} />
                </a>
              </li>
            )
          }

          return (
            <li
              key={index}
              className={`${styles.pageLink} ${
                currentPage === page ? styles.linkActive : {}
              }`}
            >
              <a className={styles.pageLink} href={path}>
                {page}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Pager.propTypes = {
  className: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  linkGenerator: PropTypes.func.isRequired
}

export default Pager