import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"
import { useContentfulBlogPostCategory } from "./useContentfulBlogPostCategory"
import CategorySectionItem from "./CategorySectionItem"
import { getBlogCategoryPath } from "../../utilities/blogUtilities"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import styles from "./CategorySection.module.scss"
import "./ScrollMenu.css"
import t from "../../translations"
import leftArrow from "./scroll-left.svg"
import rightArrow from "./scroll-right.svg"

const CategorySection = props => {
  const { locale, defaultLocale } = props
  const { categories } = useContentfulBlogPostCategory(locale)

  return (
    <div className={styles.categorySection}>
      <Container>
        <h2 className={styles.title}>
          {t.translate(t.KEYS.CATEGORIES, locale)}
        </h2>
        <div className={styles.categoriesDesktop}>
          {categories.map(category => (
            <CategorySectionItem
              itemId={category.id}
              key={category.id}
              title={category.name}
              thumbnail={category.thumbnail}
              url={getBlogCategoryPath(category, 1, locale, defaultLocale)}
            />
          ))}
        </div>
        <div className={styles.categoriesMobile}>
          <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
            {categories.map(category => (
              <CategorySectionItem
                itemId={category.id}
                key={category.id}
                title={category.name}
                thumbnail={category.thumbnail}
                url={getBlogCategoryPath(category, 1, locale, defaultLocale)}
              />
            ))}
          </ScrollMenu>
        </div>
      </Container>
    </div>
  )
}

CategorySection.propTypes = {
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired
}

export default CategorySection

/* extra elements */
function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  return (
    <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <img src={leftArrow} alt="Scroll left" />
    </Arrow>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <img src={rightArrow} alt="Scroll right" />
    </Arrow>
  )
}

function Arrow({ children, disabled, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        background: "none",
        border: "none",
        cursor: disabled ? "not-allowed" : "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        opacity: disabled ? "0.25" : "1",
        userSelect: "none"
      }}
    >
      {children}
    </button>
  )
}

Arrow.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}