import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  getArticlePath,
  getBlogCategoryPath
} from "../../utilities/blogUtilities"
import NewsletterBlock from "../../templates/Blog/NewsletterBlock"
import ReadingTime from "../ReadingTime"

import styles from "./ArticleGrid.module.scss"

const ArticleGrid = props => {
  const {
    showNewsletterBlock,
    className,
    header,
    title,
    articles,
    footer,
    locale,
    defaultLocale
  } = props

  return (
    <div className={[styles.articleGrid, className].join(" ")}>
      <Container>
        {header}
        {articles.length > 0 && (
          <>
            {title && <h2 className={styles.mainTitle}>{title}</h2>}
            <Row>
              {articles.map(article => (
                <ArticleItem
                  key={article.id}
                  article={article}
                  locale={locale}
                  defaultLocale={defaultLocale}
                />
              ))}
            </Row>
          </>
        )}
        {footer}
      </Container>
    </div>
  )
}

ArticleGrid.defaultProps = {
  showNewsletterBlock: false,
  header: null,
  footer: null
}

ArticleGrid.propTypes = {
  showNewsletterBlock: PropTypes.bool.isRequired,
  className: PropTypes.string,
  header: PropTypes.node,
  title: PropTypes.string,
  articles: PropTypes.array.isRequired,
  footer: PropTypes.node,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired
}

export default ArticleGrid

const ArticleItem = props => {
  const { article, locale, defaultLocale } = props
  const data = useStaticQuery(graphql`
    query {
      placeholder: file(
        relativePath: { eq: "placeholder-missingblogimage.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 512
            aspectRatio: 1.3
            quality: 100
          )
        }
      }
    }
  `)

  const path = getArticlePath(article, locale, defaultLocale)
  const categoryPath = getBlogCategoryPath(
    article.category,
    1,
    locale,
    defaultLocale
  )
  const thumbnail = article.thumbnail?.gatsbyImageData
  const placeholder = data.placeholder.childImageSharp.gatsbyImageData
  const timeToRead = _.get(article, "body.childMarkdownRemark.timeToRead")

  const replacePlaceholders = (string, replacements) => {
    return string.replace(/{{(\w+)}}/g, (_, key) => replacements[key] || "")
  }
  // Replace {year} with the current year
  const dynamicTitle = replacePlaceholders(article.title, {
    year: new Date().getFullYear()
  })

  return (
    <Col className="mb-6" key={article.id} sm={6} md={4} lg={3}>
      <div className={styles.article}>
        <a href={path} className={styles.articleImgLink}>
          <GatsbyImage
            image={thumbnail || placeholder}
            alt={article.thumbnail?.description || ""}
          />
        </a>
        <div className={styles.articleInfos}>
          <div className={styles.articleTopInfos}>
            <a href={categoryPath} className={styles.category}>
              {_.get(article, "category.name", "")}
            </a>
            <h3 style={{ fontSize: "1rem" }}>
              <a href={path} className={styles.title}>
                {dynamicTitle}
              </a>
            </h3>
          </div>
          {timeToRead && (
            <ReadingTime locale={props.locale} timeString={timeToRead} />
          )}
        </div>
      </div>
    </Col>
  )
}

ArticleItem.propTypes = {
  article: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired
}